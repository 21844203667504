/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { NextComponentType } from 'next';
import { AppContext, AppProps } from 'next/app';
import { NextRouter, useRouter } from 'next/router';
import styled, { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/nextjs';

import Cookies from 'js-cookie';
import AlertServiceProvider from '../components/Alert';
import CookieBar from '../components/CookieBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AppContext as StoreContext } from '../store';
import { light } from '../styles/themes';

import '../../public/styles/zoom.css';
import '../../public/styles/normalize.css';
import '../../public/styles/global.css';
import '../../public/styles/flickity.css';
import { convertFlagBagIntoPosthogRecords, routeHasNotBeenCaptured, trackClickAnalytics } from '../utils/analytics';
import { fetchUserIdentifier } from '../utils/localstorage';
import { FlagBagType, FlaggingContext, emptyFlagBag } from '../context/flagging';
import { SpamFlaggingProvider } from '../context/spamFlagging';
import FlaggingComponent from '../components/FlaggingComponent';
import { CookieConsent } from '../types/cookies';
import { PageAppearanceContext } from '../context/theme';
import { NavTheme } from '../types/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ContainerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.containerBackground};
`;

const Container = styled.div`
  min-height: 100vh;
  max-width: ${({ theme }) => theme.siteWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.neutral2};
  word-wrap: break-word;
`;

const ContentWrapper = styled.div<{ backgroundColour?: string }>`
  background: ${({ theme, backgroundColour }) => backgroundColour || theme.colors.neutral1};
  padding-bottom: 48px;
`;

interface WebsiteAppInitialProps {
  flagBag?: FlagBagType;
  isInEU?: boolean;
}

const WebsiteApp: NextComponentType<AppContext, WebsiteAppInitialProps, AppProps> = ({
  Component,
  pageProps,
}: AppProps<WebsiteAppInitialProps>) => {
  const router = useRouter();
  const [navTheme, setNavTheme] = useState<NavTheme | undefined>();
  const [backgroundColour, setBackgroundColour] = useState<string | undefined>();

  const flagBag = pageProps?.flagBag || emptyFlagBag;
  const isInEU = pageProps?.isInEU || false;
  // EU Cookies should be denied by default
  const shouldCookiesBeAccepted = (!isInEU).toString();
  const cookieConsentActioned = Cookies.get('cookie_actioned');

  if (!cookieConsentActioned) {
    Cookies.set(CookieConsent.ThirdParty, shouldCookiesBeAccepted, { expires: 365 });
    Cookies.set(CookieConsent.Statistical, shouldCookiesBeAccepted, { expires: 365 });
    Cookies.set(CookieConsent.Functional, shouldCookiesBeAccepted, { expires: 365 });
  }

  const initPostHog = (router: NextRouter) => {
    if (process.env.NEXT_PUBLIC_POSTHOG_ENABLED !== 'true') return;

    const anonID = fetchUserIdentifier();

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_STOREFRONT_KEY as string, {
      api_host: `${window.location.protocol}//${window.location.host}/ingest`,
      autocapture: false,
      capture_pageview: false,
      loaded: (posthog) => posthog.identify(anonID),
      bootstrap: {
        distinctID: anonID,
        featureFlags: convertFlagBagIntoPosthogRecords(flagBag),
      },
    });

    const statisticalCookieAccepted = Cookies.get(CookieConsent.Statistical) === 'true';
    posthog.set_config({ persistence: statisticalCookieAccepted ? 'localStorage+cookie' : 'memory' });

    if (routeHasNotBeenCaptured(router.pathname)) posthog.capture('$pageview');

    router.events.on('routeChangeComplete', (route) => {
      if (routeHasNotBeenCaptured(route)) posthog.capture('$pageview');
    });

    Cookies.set('experimentFlags', JSON.stringify(flagBag));
  };

  useEffect(() => {
    initPostHog(router);
    Sentry.setUser({ id: fetchUserIdentifier() });
    trackClickAnalytics();

    const thirdPartyCookieAccepted = Cookies.get(CookieConsent.ThirdParty) === 'true';

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (!process.env.NEXT_PUBLIC_FACEBOOK_PIXEL) {
          return;
        }

        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL);
        if (thirdPartyCookieAccepted) {
          ReactPixel.grantConsent();
        } else {
          ReactPixel.revokeConsent();
        }
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });

    if (!thirdPartyCookieAccepted) {
      // Setting Klaviyo cookies off, but keeps their javascript functionality
      Cookies.set('_kla_off', 'true', { expires: 365 });

      if (typeof window !== 'undefined' && window.TriplePixel) {
        window.TriplePixel('gdpr', true);
      }
      if (typeof window !== 'undefined' && window.clarity) {
        window.clarity('consent', false);
      }
    }
  }, [router.events]);

  const handleNavThemeChange = (navTheme: NavTheme | undefined) => {
    setNavTheme(navTheme);
  };

  const handleBackgroundColourChange = (backgroundColour: string | undefined) => {
    setBackgroundColour(backgroundColour);
  };

  return (
    <ThemeProvider theme={light}>
      <AlertServiceProvider>
        <FlaggingContext.Provider value={flagBag}>
          <FlaggingComponent flagBag={flagBag} />
          <SpamFlaggingProvider>
            <StoreContext>
              <ContainerWrapper>
                <Container>
                  <Header navTheme={navTheme} />
                  <PageAppearanceContext.Provider value={{ handleNavThemeChange, handleBackgroundColourChange }}>
                    <ContentWrapper backgroundColour={backgroundColour}>
                      <Component {...pageProps} />
                      <Analytics />
                    </ContentWrapper>
                  </PageAppearanceContext.Provider>
                  <Footer />
                  <CookieBar isInEU={isInEU} />
                </Container>
              </ContainerWrapper>
            </StoreContext>
          </SpamFlaggingProvider>
        </FlaggingContext.Provider>
      </AlertServiceProvider>
    </ThemeProvider>
  );
};

export default WebsiteApp;
